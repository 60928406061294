import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  getDoc,
  connectFirestoreEmulator,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyA_TwWr9ZwoYJ4Y13mD42pHCo1kzTru4kc",
  authDomain: "quicksharefile-5553e.firebaseapp.com",
  projectId: "quicksharefile-5553e",
  storageBucket: "quicksharefile-5553e.appspot.com",
  messagingSenderId: "1078674153680",
  appId: "1:1078674153680:web:33cda8ed2e28402ec43ed5",
  measurementId: "G-XE3BCDEGGQ",
};

function dec2hex(dec) {
  return dec.toString(16).padStart(2, "0");
}

// generateId :: Integer -> String
function generateId(len) {
  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const analytics = getAnalytics();

let env = "prod";
if (process.env.NODE_ENV !== "production") {
  connectFirestoreEmulator(db, "localhost", 8080);
  connectStorageEmulator(storage, "localhost", 9199);
  env = "dev";
}

const uploadFile = async (file) => {
  // console.log(file);
  const { name, size, type } = file;
  const fileId = generateId(6);
  const uploadedAt = Date.now();
  const fileRef = ref(storage, `/uploads/${fileId}/${name}`);
  const data = {
    name,
    size,
    type,
    uploadedAt,
    fileId,
    path: fileRef.fullPath,
    env,
  };

  await uploadBytes(fileRef, file).then((snapshot) => {
    // console.log("Uploaded a blob or file!", snapshot);
    return addDoc(collection(db, "uploads"), data);
  });
  return data;
};

const getFileInfo = async (fileId) => {
  const snapshot = await getDocs(
    query(collection(db, "uploads"), where("fileId", "==", fileId))
  );
  if (snapshot && snapshot.docs.length > 0) {
    const data = snapshot.docs[0].data();
    const fileRef = ref(storage, data.path);
    // console.log("fileinfo:", data, fileRef);
    const downloadUrl = await getDownloadURL(fileRef);
    return { ...data, downloadUrl };
  }
  return null;
};

const gaLogEvent = (eventName, data) => logEvent(analytics, eventName, data);

export { uploadFile, getFileInfo, getDoc, gaLogEvent };
